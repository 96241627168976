
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code17 } from "./data";

export default defineComponent({
  name: "tree-data-and-lazy-mode",
  data() {
    return {
      tableData: [
        {
          id: 1,
          date: "2016-05-02",
          name: "wangxiaohu"
        },
        {
          id: 2,
          date: "2016-05-04",
          name: "wangxiaohu"
        },
        {
          id: 3,
          date: "2016-05-01",
          name: "wangxiaohu",
          children: [
            {
              id: 31,
              date: "2016-05-01",
              name: "wangxiaohu"
            },
            {
              id: 32,
              date: "2016-05-01",
              name: "wangxiaohu"
            }
          ]
        },
        {
          id: 4,
          date: "2016-05-03",
          name: "wangxiaohu"
        }
      ],
      tableData1: [
        {
          id: 1,
          date: "2016-05-02",
          name: "wangxiaohu"
        },
        {
          id: 2,
          date: "2016-05-04",
          name: "wangxiaohu"
        },
        {
          id: 3,
          date: "2016-05-01",
          name: "wangxiaohu",
          hasChildren: true
        },
        {
          id: 4,
          date: "2016-05-03",
          name: "wangxiaohu"
        }
      ]
    };
  },
  methods: {
    load(tree, treeNode, resolve) {
      setTimeout(() => {
        resolve([
          {
            id: 31,
            date: "2016-05-01",
            name: "wangxiaohu"
          },
          {
            id: 32,
            date: "2016-05-01",
            name: "wangxiaohu"
          }
        ]);
      }, 1000);
    }
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code17
    };
  }
});
